
import {
    defineComponent, reactive, onBeforeMount, computed,
} from 'vue';
import { onBeforeRouteLeave } from 'vue-router';
import Screen from '@/components/layout/Screen.vue';
import Transaction from '@/domain/Transaction';
import Item from '@/domain/Item';
import SupplierShipmentService from '@/services/SupplierShipmentService';
import Shipment from '@/domain/Shipment';
import coreStore from '@/store/CoreStore';
import { Formatter, TransactionStatus } from '@/domain/TransactionStatus';
import useDialogBox from '@/components/bootstrap-library/composables/useDialogBox';
import MasterDataRouteTypes from '@/modules/master-data/routes/types';
import BFormInput from '@/components/bootstrap-library/BFormInput.vue';
import BSpinner from '@/components/bootstrap-library/BSpinner.vue';
import TransactionCloseoutDisputeButton from '@/components/buttons/TransactionCloseoutDisputeButton.vue';
import TransactionFooter from '@/components/TransactionFooter.vue';
import useSupplierShipping from '@/composable/useSupplierShipping';
import TransactionState from '@/interfaces/TransactionState';
import BTable, { BTableField } from '@/components/bootstrap-library/table/BTable/BTable.vue';
import useUnitLoadGrouping from '@/composable/useUnitLoadGrouping';
import InventoryCategory from '@/domain/InventoryCategory';
import TotalQuantityInventoryAdjustmentDTO from '@/dtos/TotalQuantityInventoryAdjustmentDTO';
import ItemQuantity from '@/interfaces/ItemQuantity';
import InventoryService from '@/services/InventoryService';
import MasterDataSupplierShippingHead from '@/modules/master-data/views/transactions/supplier-shipping/components/MasterDataSupplierShippingHead.vue';
import router from '@/router';
import EntityType from '@/domain/enums/EntityTypes';
import Thumbnail from '@/components/Thumbnail.vue';
import { getTitleCaseTranslation, getTranslation } from '@/services/TranslationService';
import Permissions from '@/services/permissions/Permissions';
import TransactionService from '@/services/TransactionService';
import { formatDateUI } from '@/functions/date';
import LocationService from '@/services/LocationService';

interface State extends TransactionState {
    dispositionInvAdj: TotalQuantityInventoryAdjustmentDTO[];
    originalDispositionInvAdjStringified: string;
    itemList: Array<ItemQuantity | ItemQuantity[]>;
    showNotes: boolean;
    notes: string;
    showTags: boolean;
    isServiceCenter: boolean;
}

type TagTableData = {
    item?: any;
    barcode?: string;
    productionPlant?: string;
    productionDate?: Date;
}

export default defineComponent({
    name: 'master-data-confirm-dispute-transaction-screen',
    components: {
        Thumbnail,
        MasterDataSupplierShippingHead,
        BSpinner,
        BFormInput,
        Screen,
        TransactionCloseoutDisputeButton,
        TransactionFooter,
        BTable,
    },
    props: {
        modelValue: {
            type: Transaction,
            default: () => new Transaction(),
        },
    },
    setup(props) {
        const inventoryService = new InventoryService();
        const shipmentService = new SupplierShipmentService();
        const transactionService = new TransactionService();
        const locationService = new LocationService();

        const { userLocation, id } = coreStore.getInstance().profileStore;

        const { confirm } = useDialogBox();

        const state = reactive<State>({
            shipment: new Shipment(),
            transaction: new Transaction(),
            originalShipmentStringified: '',
            originalDispositionInvAdjStringified: '',
            loading: false,
            itemList: [],
            dispositionInvAdj: [],
            disputing: false,
            saving: false,
            showNotes: false,
            notes: '',
            showTags: false,
            isServiceCenter: false,
        });

        const {
            closeoutDisputed,
            goToTransactionList,
            isDirty,
            updateExistingShipment,
            isReceivedTransactionLineFieldsDisabled,
            transactionLineErrors,
            validationTransactionResult: transactionValidationResult,
            validationShipmentResult: shipmentValidationResult,
        } = useSupplierShipping({
            transactionListRouteName: MasterDataRouteTypes.TRANSACTION.LIST,
            state: (state as unknown) as TransactionState,
            formValidationKeyForTransactions: state.disputing ? 'transaction-dispute' : undefined,
            formValidationKeyForShipments: 'shipment',
        });

        const { getTransactionLinesInUnitLoadGroupOrder, mapTransactionLinesForTable } = useUnitLoadGrouping();

        const hasTags = computed(() => state.transaction.transactionLines.some((line) => line.transactionLineDetails.length > 0));

        const allowAdjustConfirmed = computed(() => (
            state.transaction.canAdminAdjust()
            && Permissions.ADMINISTRATION.canAdjustConfirmed()
        ));

        const dispositionFields = computed(
            (): Array<string> => {
                const distinctInvCat: InventoryCategory[] = [];
                state.dispositionInvAdj.forEach((invAdj) => {
                    if (!distinctInvCat.some((x) => x.id === invAdj.inventoryCategory.id) && (allowAdjustConfirmed.value || invAdj.quantity !== 0)) {
                        distinctInvCat.push(invAdj.inventoryCategory);
                    }
                });

                return [...distinctInvCat].sort((a, b) => b.id - a.id).map((disInvCat) => disInvCat.description);
            },
        );

        const isInbound = computed(() => state.transaction.getDirection(userLocation) === 'inbound');
        const showEstimatedQuantityColumn = computed(() => state.transaction.transactionLines.some((line) => line.estimatedQuantity > 0));

        const transactionLineFields = computed(
            (): Array<BTableField<Record<string, string>>> => {
                const fields: Array<BTableField<Record<string, string>>> = [
                    {
                        key: 'imageUrlThumb',
                        label: getTitleCaseTranslation('core.domain.image'),
                    },
                    {
                        key: 'name',
                        label: getTitleCaseTranslation('core.domain.name'),
                    },
                    {
                        key: 'shortName',
                        label: getTitleCaseTranslation('core.domain.shortName'),
                    },
                    {
                        key: 'plannedQuantity',
                        label: getTitleCaseTranslation('core.domain.plannedQuantity'),
                    },
                    {
                        key: 'actualQuantity',
                        label: getTitleCaseTranslation('core.domain.actualQuantity'),
                    },
                ];

                if (showEstimatedQuantityColumn.value && isInbound.value) {
                    fields.push({
                        key: 'estimatedQuantity',
                        label: getTitleCaseTranslation('core.domain.estimatedQuantity'),
                    });
                }

                if (dispositionFields.value.length) {
                    fields.push(...dispositionFields.value);
                }

                if (state.transaction.status === TransactionStatus.DISPUTED) {
                    fields.push({
                        key: 'receivedQuantity',
                        label: getTitleCaseTranslation('core.domain.receivedQuantity'),
                    });
                }

                return fields;
            },
        );

        const isTransactionLineFieldsDisabled = computed(
            () => !(state.transaction.canEdit(userLocation) || (Permissions.ADMINISTRATION.canCloseoutDisputedTransactions() && state.transaction.status === TransactionStatus.DISPUTED)),
        );
        const isActualQuantityFieldDisabled = computed(() => (
            (!allowAdjustConfirmed.value || state.dispositionInvAdj.length > 0) && isTransactionLineFieldsDisabled.value
        ));
        const isPlannedQuantityFieldDisabled = computed(() => isTransactionLineFieldsDisabled.value && (state.disputing || state.transaction.status > TransactionStatus.ORDERED));

        const canSaveChanges = computed(() => state.transaction.canAdminEdit(userLocation) || state.transaction.canAdminEditDockAndTimeslot(userLocation) || allowAdjustConfirmed.value);

        const dispositionChanges = computed(() => state.originalDispositionInvAdjStringified !== JSON.stringify(state.dispositionInvAdj));

        async function fetchShipmentDetails(transactionId: number) {
            const response = await shipmentService.getShipmentDetailsForTransaction(transactionId);
            if (response.success) {
                state.shipment = response.shipmentDetails;
                state.shipment.fromLocation = props.modelValue?.fromLocation;
            }
        }

        async function fetchDispositionData(transactionId: number) {
            const response = await inventoryService.getTotalPutAwayInventoryAdjustments(transactionId);

            if (response) {
                state.dispositionInvAdj = response;
                state.originalDispositionInvAdjStringified = JSON.stringify(response);
            }
        }

        async function populateTransactionLines(transaction: Transaction) {
            const transactionLinesInUnitLoadGroupingOrder = getTransactionLinesInUnitLoadGroupOrder(transaction);
            state.itemList = mapTransactionLinesForTable(transactionLinesInUnitLoadGroupingOrder, transaction.status);
        }

        async function initExisting() {
            state.loading = true;
            state.transaction = props.modelValue;
            await fetchShipmentDetails(state.transaction.id);
            await populateTransactionLines(state.transaction as Transaction);
            await fetchDispositionData(state.transaction.id);
            state.shipment.transactions.push(state.transaction);
            state.originalShipmentStringified = JSON.stringify(state.shipment);
            state.loading = false;
        }

        onBeforeMount(async () => {
            if (props.modelValue) {
                await initExisting();
            }

            if (state.transaction.toLocation.id) {
                const toLocation = await locationService.getLocationById(state.transaction.toLocation.id);
                if (toLocation) {
                    state.isServiceCenter = toLocation.isServiceCenter;
                }
            }
        });

        const itemPlannedQuantityChange = (item: Item, qty: number) => {
            state.transaction.setPlannedItemQty(item, qty);
        };

        const itemActualQuantityChange = (item: Item, qty: number, unitLoadParentId?: number) => {
            state.transaction.setActualItemQty(item, qty, unitLoadParentId);
        };

        const itemReceivedQuantityChange = (item: Item, qty: number, unitLoadParentId?: number) => {
            state.transaction.setReceivedQuantity(item, qty, unitLoadParentId);
        };

        async function confirmLeave() {
            if (isDirty.value) {
                return confirm({
                    title: getTitleCaseTranslation('core.common.areYouSure'),
                    message: getTranslation('core.common.allUnsavedDataWillBeLostWhenYouLeaveThisPage'),
                });
            }
            return true;
        }

        async function cancel() {
            goToTransactionList();
        }

        onBeforeRouteLeave(async () => confirmLeave());

        async function goBack() {
            router.back();
        }

        async function openHistory() {
            router.push({
                name: MasterDataRouteTypes.HISTORY.LIST,
                params: { entityType: EntityType.TRANSACTION, entityId: props.modelValue!.id },
            });
        }

        async function openNotes() {
            state.notes = state.shipment.deliveryNotes ?? '';
            state.showNotes = true;
        }

        async function resetNotes() {
            state.notes = state.shipment.deliveryNotes ?? '';
            state.showNotes = false;
        }

        async function saveNotes() {
            if (!state.shipment.id) {
                state.showNotes = false;
                return;
            }

            const response = await shipmentService.updateShipmentNotes(state.shipment.id, state.notes);
            if (response) {
                state.shipment.deliveryNotes = state.notes;
            }
            state.showNotes = false;
        }

        function getDispositionValueFromInventoryAdjustments(itemId: number, dispositionField: string): number | undefined {
            return state.dispositionInvAdj.find((invAdj) => invAdj.itemId === itemId && invAdj.inventoryCategory.description === dispositionField)?.quantity;
        }

        function setDispositionValueForInventoryAdjustments(itemId: number, dispositionField: string, qty: number): void {
            const field = state.dispositionInvAdj.find((invAdj) => invAdj.itemId === itemId && invAdj.inventoryCategory.description === dispositionField);

            if (field) {
                field.quantity = qty;
            }
        }

        const navbarTitle = computed((): string => {
            if (state.transaction.id) {
                return getTranslation('core.common.transactionNumberTitle', state.transaction.id, Formatter.GetFriendlyValue(state.transaction.status));
            }
            return '';
        });

        async function fetchTransactionData() {
            const response = await transactionService.getTransactionById(state.transaction.id);

            if (response.success) {
                state.transaction = response.transaction;
                await populateTransactionLines(response.transaction);
            }
        }

        async function saveTransaction() {
            state.saving = true;
            const updateResponse = await updateExistingShipment();
            if (dispositionChanges.value && updateResponse) {
                const response = await inventoryService.adjustConfirmedTransaction({
                    transactionId: state.transaction.id,
                    createdById: id,
                    inventoryAdjustmentQuantities: state.dispositionInvAdj,
                });
                state.dispositionInvAdj = response;
                state.originalDispositionInvAdjStringified = JSON.stringify(response);

                await fetchTransactionData();
            }
            // Reload the inventory categories so we don't keep dirty values
            await fetchDispositionData(state.transaction.id);
            await populateTransactionLines(state.transaction as Transaction);
            state.saving = false;
        }

        const trackedItemLineFields = computed(
            (): Array<BTableField<TagTableData>> => {
                const fields: BTableField<TagTableData>[] = [
                    { key: 'item', label: getTitleCaseTranslation('core.domain.item'), ignoreSort: false },
                    { key: 'barcode', label: getTitleCaseTranslation('core.domain.barcode'), ignoreSort: true },
                    { key: 'productionPlant', label: getTitleCaseTranslation('core.domain.productionPlant'), ignoreSort: true },
                    { key: 'productionDate', label: getTitleCaseTranslation('core.domain.productionDate'), ignoreSort: true },
                ];

                return fields;
            },
        );

        const trackedItemLines = computed(
            (): Array<TagTableData | TagTableData[]> => {
                const trackedItems: Array<TagTableData | TagTableData[]> = [];
                state.transaction.transactionLines.forEach((line) => {
                    if (line.trackedItemList.length > 0) {
                        // customerItemNumber is nullable so we don't want a hanging hyphen if we're missing that property
                        const itemName = line.item.customerItemNumber ? `${line.item.name} - ${line.item.customerItemNumber}` : line.item.name;
                        trackedItems.push({
                            item: itemName,
                            barcode: undefined,
                            productionPlant: undefined,
                            productionDate: undefined,
                        });
                        trackedItems.push(line.trackedItemList.map((trackedItem) => ({
                            item: undefined,
                            barcode: trackedItem.barcode,
                            productionPlant: trackedItem.productionPlant,
                            productionDate: trackedItem.productionDate ? new Date(trackedItem.productionDate) : undefined,
                        })));
                    }
                });
                return trackedItems;
            },
        );

        // service centers are only enabled at statuses greater than confirmed
        const disableAdjustments = computed((): boolean => state.transaction.status < TransactionStatus.CONFIRMED && state.isServiceCenter);

        return {
            state,
            fetchRouteConfigItems: populateTransactionLines,
            isDirty,
            cancel,
            goBack,
            goToTransactionList,
            openHistory,
            openNotes,
            saveNotes,
            resetNotes,
            userLocation,
            closeoutDisputed,
            isReceivedTransactionLineFieldsDisabled,
            isTransactionLineFieldsDisabled,
            isActualQuantityFieldDisabled,
            isPlannedQuantityFieldDisabled,
            itemReceivedQuantityChange,
            itemPlannedQuantityChange,
            dispositionFields,
            getDispositionValueFromInventoryAdjustments,
            setDispositionValueForInventoryAdjustments,
            itemActualQuantityChange,
            transactionLineErrors,
            transactionLineFields,
            TransactionStatus,
            transactionValidationResult,
            shipmentValidationResult,
            getTitleCaseTranslation,
            getTranslation,
            navbarTitle,
            saveTransaction,
            Permissions,
            canSaveChanges,
            dispositionChanges,
            allowAdjustConfirmed,
            hasTags,
            trackedItemLineFields,
            trackedItemLines,
            formatDateUI,
            disableAdjustments,
        };
    },
});
